// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './theme';
@import '@angular/cdk/overlay-prebuilt.css';

@include mat.all-component-themes($dynamic-app-theme);

:root {
  --primary: #038CC9;
  --accent: #ffd005;
  --mat-form-field-container-vertical-padding: 18px;
  --mat-dialog-container-min-width: 25vw;
  --mat-form-field-subscript-text-size: 0.7em;
  --mat-badge-small-size-text-size: 0.5em;
  --mdc-chip-label-text-size: 0.8em;
  --mdc-dialog-supporting-text-size: 1em;
  --mat-table-row-item-label-text-size: 1em;
  --mdc-dialog-subhead-size: 1em;
  --mat-table-header-headline-size: 1em;
  --font-size: 100%;
  --font-family: roboto;
  --mat-dialog-container-small-max-width: 90vw;
  --mat-card-subtitle-text-size: 1em;
  --mdc-dialog-subhead-font: var(--font-family);
  --mat-table-header-headline-font: var(--font-family);
  --mat-table-row-item-label-text-font: var(--font-family);
  --mdc-dialog-supporting-text-font: var(--font-family);
  --mat-badge-text-font: var(--font-family);
  --mat-expansion-header-collapsed-state-height: fit-content;
  --mdc-protected-button-container-height: unset;
  --mdc-outlined-text-field-focus-outline-width: 3px;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/material.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.1em;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mdc-fab--mini .material-icons {
  font-size: 24px;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/roboto/Roboto-Thin.ttf');
}


@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/roboto/Roboto-LightItalic.ttf');

}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/roboto/Roboto-Italic.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/roboto/Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/roboto/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/roboto/Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: "OpenDyslexicAlta";
  src: url('assets/fonts/openDyslexic/OpenDyslexic-Regular.otf') format("otf"), url("assets/fonts/openDyslexic/OpenDyslexicAlta-Regular.eot") format("eot"), url("assets/fonts/openDyslexic/OpenDyslexicAlta-Regular.woff") format("woff"), url("assets/fonts/openDyslexic/OpenDyslexicAlta-Regular.ttf") format("truetype"), url("assets/fonts/openDyslexic/OpenDyslexicAlta-Regular.svg") format("svg");
}

@font-face {
  font-family: AtkinsonHyperlegible;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/atkinsonHyperlegible/AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
  font-family: AtkinsonHyperlegible;
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/atkinsonHyperlegible/AtkinsonHyperlegible-Bold.ttf');
}

@font-face {
  font-family: AtkinsonHyperlegible;
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/atkinsonHyperlegible/AtkinsonHyperlegible-Italic.ttf');
}

@font-face {
  font-family: AtkinsonHyperlegible;
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/atkinsonHyperlegible/AtkinsonHyperlegible-BoldItalic.ttf');
}

.mat-background-primary {
  background-color: var(--primary) !important;
}

.primaryContrast, .primaryContrast * {
  color: var(--primary-text)
}

.mat-background-accent {
  background-color: var(--accent) !important;
}

.accentContrast, .accentContrast * {
  color: var(--accent-text)
}

html, body {
  height: 100%;
  background-color: #F5F3ED;
}

body {
  margin: 0;
  font-family: var(--font-family), "Helvetica Neue", sans-serif;
  font-size: var(--font-size) !important;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-content {
  width: fit-content;
}

.h-content {
  height: fit-content;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.flex-auto {
  flex: auto;
}

.flex-col {
  display: flex;
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none;
}

.hidden {
  display: none !important;
}

.d-block {
  display: block;
}

.flex-row {
  display: flex;
  flex-direction: row !important;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-grid {
  display: grid;
}

.flex-center {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around !important;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-end {
  justify-content: end;
}

.gap10 {
  gap: 10px
}

.py-15 {
  padding: 15px 0;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-10 {
  padding-left: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-0 {
  padding: 0 !important;
}

.minFullHeight {
  min-height: calc(100vh - 70px);
  position: relative;
}

mat-icon {
  width: auto !important;

  mat-spinner {
    width: 100% !important;
    height: 100% !important;
  }
}

.noBottomPadding .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.bg-transparent {
  background-color: transparent !important;
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.clickable, .clickable * {
  cursor: pointer !important;
}


.memoriesContainer {
  gap: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  mat-card-title {
    font-weight: bold;
  }

  mat-card-content {
    padding-top: 5px;

    small {
      font-size: 0.8em;
    }

    p {
      font-size: 0.7em;
    }
  }
}


@media screen and (max-width: 1000px) {
  .memoriesContainer {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 800px) {
  .memoriesContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 470px) {
  .memoriesContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.overflow-hidden {
  overflow: hidden;
}

.smallIcon {
  padding: 0 !important;
  height: fit-content !important;
  width: fit-content !important;
  aspect-ratio: 1;
}

mat-card .mat-expansion-panel-body {
  padding: 0;
}

.mediumContainer {
  padding-top: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.largeContainer {
  padding-top: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

@media screen and (max-width: 800px) {
  .mediumContainer {
    width: 90%;
  }
}


.list-wrapping {
  max-width: 250px;
}

.largeWindow {
  width: 80vw;
  height: 90vh;
}

.fullWidthWindow {
  width: 80vw;
  max-height: 90vh;
}

.mat-mdc-chip.mat-primary {
  background-color: var(--primary) !important;

  .mat-mdc-chip-action-label {
    color: var(--theme-primary-contrast-500) !important;
  }
}

.mat-mdc-chip.mat-warn {
  background-color: #f44336 !important;

  .mat-mdc-chip-action-label {
    color: white !important;
  }
}

.gap-15 {
  gap: 15px;
}

.gap-25 {
  gap: 25px;
}

@media screen and (max-width: 1150px) {


  .responsiveTable.openSidebar {
    padding-top: 10px;

    .cdk-column-name {
      max-width: unset !important;
    }

    .mat-mdc-header-row {
      display: none
    }

    .columnTitle {
      display: block;
      margin: 0;
    }
    .hideMobile{
      display: none;
    }

    .mat-mdc-cell:first-child {
      border-top: solid 1.5px var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    }

    .mat-mdc-row {
      flex-direction: column !important;
      align-items: flex-start !important;
      gap: 10px;
      display: flex;
      height: auto;
      border-left: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      border-right: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));


      .mat-mdc-cell {
        width: 100%;
        min-height: fit-content;
        text-align: center;
        padding: 5px 15px;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
      }
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
      border-bottom: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      letter-spacing: var(--mat-table-row-item-label-text-tracking);
    }
  }
}


@media screen and (max-width: 900px) {

  .responsiveTable.massiveTable {
    padding-top: 10px;

    .cdk-column-name {
      max-width: unset !important;
    }


    .mat-mdc-header-row {
      display: none
    }

    .columnTitle {
      display: block;
      margin: 0;
    }
    .hideMobile{
      display: none;
    }

    .mat-mdc-cell:first-child {
      border-top: solid 1.5px var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    }

    .mat-mdc-row {
      flex-direction: column !important;
      align-items: flex-start !important;
      gap: 10px;
      display: flex;
      height: auto;
      border-left: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      border-right: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));


      .mat-mdc-cell {
        width: 100%;
        min-height: fit-content;
        text-align: center;
        padding: 5px 15px;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
      }
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
      border-bottom: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      letter-spacing: var(--mat-table-row-item-label-text-tracking);
    }
  }
}

@media screen and (max-width: 780px) {
  .largeWindow {
    width: 90vw;
  }

  .fullWidthWindow {
    width: 90vw;
  }

  .responsiveTable {
    padding-top: 10px;

    .cdk-column-name {
      max-width: unset !important;
    }

    .mat-mdc-header-row {
      display: none
    }

    .columnTitle {
      display: block;
      margin: 0;
    }
    .hideMobile{
      display: none;
    }

    .mat-mdc-cell:first-child {
      border-top: solid 1.5px var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    }

    .mat-mdc-row {
      flex-direction: column !important;
      align-items: flex-start !important;
      gap: 10px;
      display: flex;
      height: auto;
      border-left: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      border-right: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));


      .mat-mdc-cell {
        width: 100%;
        min-height: fit-content;
        text-align: center;
        padding: 5px 15px;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
      }
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
      border-bottom: solid var(--mat-table-row-item-outline-width, 1px) var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
      letter-spacing: var(--mat-table-row-item-label-text-tracking);
    }
  }
}

.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}

.mdc-evolution-chip__text-label {
  white-space: nowrap;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-underline {
  text-decoration: underline;
}


.fieldContainer {
  gap: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media screen and (max-width: 700px) {
  .fieldContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.mat-expansion-indicator::after {
  padding: 5px !important;
  color: var(--primary) !important;
}

.customIcon {
  transform: scale(0.8);
  max-width: 25px;
}

.errorMsg {
  color: var(--mat-form-field-error-text-color);
}

mat-expansion-panel-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.mat-dialog-close {
  padding: 0px !important;
}

.cdk-visually-hidden {
  display: none;
}

.squareImage {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.mdc-button {
  min-height: 36px;
}

.muted {
  color: #a8a8a8;
}

.centerLoading {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
}

.primaryBg {
  background-color: var(--primary);
}

.flex-grow {
  flex-grow: 1;
}

.primaryContrast .mdc-list-item__primary-text, .primaryContrast .mat-icon {
  color: var(--primary-text) !important;
}

app-media-display {
  display: flex;
  flex-direction: column;
}

.f-20 {
  font-size: 20px !important;
}

.f-18 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.f-13 {
  font-size: 13px;
}

.f-12 {
  font-size: 12px;
}

.smallProfilePicture {
  height: 40px;
  width: 40px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 100%;
}

.bg-white {
  background-color: white;
}

.mat-column-checkbox {
  width: 40px;
}

.mat-calendar-body-active {
  .mat-calendar-body-cell-content {
    display: inline-flex;
    justify-content: space-evenly;
  }
}

.mat-mdc-progress-spinner {
  min-width: 18px !important;
}

.mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background-color: #e64d43;
  color: white;

  .mdc-button__label {
    color: white;
  }
}

.initial {
  background-color: var(--primary);
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 60px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--primary-text);
  text-transform: uppercase;
}

.flex-wrap {
  flex-wrap: wrap;
}

.warn {
  .mdc-snackbar__surface {
    background-color: orange !important;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action {
    color: green !important;
  }
}

.mat-mdc-button-base:focus {
  filter: brightness(0.8);
}

.mat-mdc-cell {
  overflow-wrap: anywhere;
}

.capitalize {
  text-transform: capitalize;
}

.h-auto {
  height: auto;
}

.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  display: none;
}

.dropdownMenuItem {
  margin: 0 !important;

  .mat-expansion-panel-header {
    padding-left: 16px;
    padding-right: 16px;

    .mat-icon {
      color: var(--mdc-list-list-item-leading-icon-color);
      padding-right: 42px;
    }

    .mat-expansion-panel-header-title {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .filterItems:not(.fewItems) {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 500px) {
  .filterItems {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 400px) {
  .filterItems {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.greeting {
  white-space: break-spaces;
}

footer {
  margin-top: 17px;
}

.noPointerEvents {
  pointer-events: none;
}

.notificationBadge .mat-badge-content.mat-badge-active {
  width: fit-content;
  padding: 1px 3px;
  border-radius: 20px;
  height: fit-content;
  margin-left: -10px;
  min-width: 18px;
}
