@use '@angular/material' as mat;
@include mat.core();

:root {
  --mdc-plain-tooltip-container-color: grey;
  --mdc-linear-progress-active-indicator-color: #e0e2ec;
}

$warning-palette: (
        50 : #fce6e4,
        100 : #f6c0bd,
        200 : #f19691,
        300 : #eb6c64,
        400 : #e64d43,
        500 : #e22d22,
        600 : #df281e,
        700 : #da2219,
        800 : #d61c14,
        900 : #cf110c,
        A100 : #fffafa,
        A200 : #ffc8c7,
        A400 : #ff9694,
        A700 : #ff7c7a,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);


$dynamic-theme-primary: (
        50 : var(--theme-primary-50),
        100 : var(--theme-primary-100),
        200 : var(--theme-primary-200),
        300 : var(--theme-primary-300),
        400 : var(--theme-primary-400),
        500 : var(--theme-primary-500),
        600 : var(--theme-primary-600),
        700 : var(--theme-primary-700),
        800 : var(--theme-primary-800),
        900 : var(--theme-primary-900),
        A100 : var(--theme-primary-A100),
        A200 : var(--theme-primary-A200),
        A400 : var(--theme-primary-A400),
        A700 : var(--theme-primary-A700),
        contrast: (
                50: var(--theme-primary-contrast-50),
                100: var(--theme-primary-contrast-100),
                200: var(--theme-primary-contrast-200),
                300: var(--theme-primary-contrast-300),
                400: var(--theme-primary-contrast-400),
                500: var(--theme-primary-contrast-500),
                600: var(--theme-primary-contrast-600),
                700: var(--theme-primary-contrast-700),
                800: var(--theme-primary-contrast-800),
                900: var(--theme-primary-contrast-900),
                A100: var(--theme-primary-contrast-A100),
                A200: var(--theme-primary-contrast-A200),
                A400: var(--theme-primary-contrast-A400),
                A700: var(--theme-primary-contrast-A700),
        )
);

$dynamic-theme-accent: (
        50 : var(--theme-accent-50),
        100 : var(--theme-accent-100),
        200 : var(--theme-accent-200),
        300 : var(--theme-accent-300),
        400 : var(--theme-accent-400),
        500 : var(--theme-accent-500),
        600 : var(--theme-accent-600),
        700 : var(--theme-accent-700),
        800 : var(--theme-accent-800),
        900 : var(--theme-accent-900),
        A100 : var(--theme-accent-A100),
        A200 : var(--theme-accent-A200),
        A400 : var(--theme-accent-A400),
        A700 : var(--theme-accent-A700),
        contrast: (
                50: var(--theme-accent-contrast-50),
                100: var(--theme-accent-contrast-100),
                200: var(--theme-accent-contrast-200),
                300: var(--theme-accent-contrast-300),
                400: var(--theme-accent-contrast-400),
                500: var(--theme-accent-contrast-500),
                600: var(--theme-accent-contrast-600),
                700: var(--theme-accent-contrast-700),
                800: var(--theme-accent-contrast-800),
                900: var(--theme-accent-contrast-900),
                A100: var(--theme-accent-contrast-A100),
                A200: var(--theme-accent-contrast-A200),
                A400: var(--theme-accent-contrast-A400),
                A700: var(--theme-accent-contrast-A700),
        )
);


$dynamic-app-theme: mat.m2-define-light-theme((
        color: (
                primary: mat.m2-define-palette($dynamic-theme-primary),
                accent: mat.m2-define-palette($dynamic-theme-accent),
                warning: mat.m2-define-palette($warning-palette)
        ),
));


.mat-primary.mat-mdc-unelevated-button *, .mat-primary.mat-mdc-raised-button *, .mat-primary.mdc-fab * {
  color: var(--theme-primary-contrast-500);
}

.mat-accent.mat-mdc-unelevated-button *, .mat-accent.mat-mdc-raised-button *, .mat-accent.mdc-fab * {
  color: var(--theme-accent-contrast-500);
}

.mat-mdc-progress-bar .mdc-linear-progress__secondary-bar .mdc-linear-progress__bar-inner, .mat-mdc-progress-bar{
  border-color: #e0e2ec;
}


.mat-datepicker-content.mat-primary .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--theme-primary-contrast-500) !important;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--theme-accent-contrast-500) !important;
}

.mat-datepicker-content .mat-calendar-body-cell:focus :before {
  display: none;
}

.bg-primary {
  background-color: var(--primary);
}
